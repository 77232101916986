export const REDDIT = {
  APP_ID: 'UYQ-F-mjnHCifw',
  APP_SECRET: 'mJZaWUpA2HKX9cNCw426Tj7QiaNe1Q',   // TODO darci: should store more securely
  APP_REDIRECT: 'https://grokdating.web.illinois.edu/',
  APP_NAME: 'grok.date.prod',

  SCOPE: 'mysubreddits',
  STATE: 'reddit-oauth2',                         // NOTE darci: maybe should be random, could use redirect URI
  
  TERMINAL_URI: "https://grokdating.web.illinois.edu/",          // Where user will end when all is done
}